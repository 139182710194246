import { Layout } from '@components/common'
import type { GetStaticPropsContext, InferGetStaticPropsType } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { NextSeo } from 'next-seo'
import { useLocale } from '@lib/locales/utils'
import NewHomePage from '../components/homepage/NewHomePage'

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'en', ['common'])),
    },
  }
}

export default function Home({}: InferGetStaticPropsType<
  typeof getStaticProps
>) {
  const { baseUrlWithLocale } = useLocale()

  return (
    <div>
      <NextSeo
        canonical={baseUrlWithLocale}
        languageAlternates={[
          {
            hrefLang: 'x-default',
            href: 'https://store.sesamy.com/',
          },
          {
            hrefLang: 'en',
            href: 'https://store.sesamy.com/',
          },
          {
            hrefLang: 'sv',
            href: 'https://store.sesamy.com/sv',
          },
          {
            hrefLang: 'da',
            href: 'https://store.sesamy.com/da',
          },
        ]}
      />
      <NewHomePage />
    </div>
  )
}

Home.Layout = Layout
